<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Nuevo Dispositivo</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <DispositivoForm></DispositivoForm>
    </div>
  </v-container>
</template>

<script>
import DispositivoForm from "../components/DispositivoForm.vue";

export default {
  name: "DispositivoAlta",
  components: {
    DispositivoForm,
  },
  data() {
    return {};
  },
};
</script>
