<template>
  <v-card>
    <v-container>
      <v-alert border="top" colored-border type="info" elevation="2">
        Centre los ojos en el recuadro verde. Posicione el rostro de forma tal
        que salga completo en el recuadro y haga clic en el botón con el
        objetivo verde cuando esté listo.
      </v-alert>
    </v-container>
    <v-container v-if="error">
      <v-row>
        <v-col>
          <v-alert dense type="error">{{ error }}</v-alert>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col class="text-center">
          <!-- <div style="width: 250px; height: 250px; align-items: center"> -->

          <img
            v-if="!camera && !cameraDone"
            src="../assets/no-photo.png"
            alt="no profile picture"
            height="225"
            width="300"
          />
          <img
            v-else-if="cameraDone"
            :src="fotoTaken"
            alt="photo-taken"
            height="225"
            width="300"
          />
          <EasyCamera
            v-show="camera"
            style="
              width: 300px;
              height: 225px;
              margin-left: auto;
              margin-right: auto;
              position: relative;
            "
            v-model="fotoTaken"
            ref="cam"
            :fullscreenZIndex="1"
            @input="cameraCloseFS"
            @loading="cameraLoad"
            :overlayMask="require('../assets/photoMask.png')"
          >
          </EasyCamera>
          <!-- <img
            v-show="camera"
            src="../assets/photoMask.png"
            alt="Photo Mask"
            height="225"
            width="300"
          /> -->

          <!-- :overlayMask="require('../assets/photoMask.png')" -->
          <!-- </div> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col> </v-col>
        <!-- <v-col class="text-center">
          <v-btn rounded @click="cameraOnOff()">
            <v-icon large> mdi-camera </v-icon>
          </v-btn>
        </v-col> -->
        <v-col>
          <v-row>
            <v-col class="text-center">
              <v-btn :disabled="loading" rounded @click="cameraShoot(camera)">
                <v-icon large :color="camera ? 'success' : 'error'">
                  {{ camera ? "mdi-camera-iris" : "mdi-reload" }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col class="text-center">
              <v-btn
                rounded
                @click="$refs.fileInput.$refs.input.click()"
                :visible="!fotoTaken"
              >
                <v-file-input
                  hide-input
                  ref="fileInput"
                  accept="image/png, image/jpeg"
                  v-model="uploadedPhoto"
                  @change="uploadPhoto($event)"
                  prepend-icon=""
                ></v-file-input>
                <v-icon large>mdi-paperclip</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col> </v-col>
      </v-row>
    </v-container>
    <v-form v-model="formValidity" ref="form" @submit.prevent="onSubmit">
      <v-container>
        <v-divider> </v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!fotoTaken"
            :loading="loading"
            color="primary"
            type="submit"
            ><v-icon left dark> mdi-check </v-icon>Guardar</v-btn
          >
          <v-btn :disabled="loading" color="error" @click="cancelBtn()"
            ><v-icon left dark> mdi-close </v-icon>Cancelar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import EmpleadosService from "../services/EmpleadosService";
import EasyCamera from "easy-vue-camera";

export default {
  name: "EmpleadoEnrolarForm",
  components: {
    EasyCamera,
  },

  data() {
    return {
      formValidity: false,
      camera: false,
      cameraDone: false,
      cameraMask: false,
      fotoTaken: null,
      error: false,
      loading: false,
      uploadedPhoto: null,
    };
  },
  props: {
    empleado_id: {
      type: String,
      required: true,
    },
    empleadoFoto: {
      type: String,
      required: true,
    },
  },
  created() {},

  methods: {
    uploadPhoto(event) {
      // console.log("size:" + event.size);
      // console.log("uploaded size:" + this.uploadedPhoto.size);
      this.error = null;
      if (this.uploadedPhoto.size > 5 * 1024 * 1024) {
        this.error =
          "El tamaño del archivo seleccionado es demasiado grande. Intente con una imagen de hasta 5MB de peso.";
        return;
      }

      this.createBase64Image(this.uploadedPhoto);

      this.cameraDone = true;
      this.camera = false;
      this.$refs.cam.close();
    },

    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.fotoTaken = e.target.result;
      };
      reader.readAsDataURL(fileObject);
      // console.log("foto taken:" + this.fotoTaken + "object: " + fileObject);
    },

    cameraCloseFS() {
      this.cameraDone = true;
      this.camera = false;
    },

    cameraShoot() {
      if (this.camera) {
        this.cameraDone = true;
        this.$refs.cam.snap();
        this.camera = false; // lo puse en false 30/03
        this.$refs.cam.close();
      } else this.cameraOnOff();
    },
    cameraLoad(estate) {
      if (estate) this.cameraOnOff();
    },

    cameraOnOff() {
      if (!this.camera) {
        this.camera = true;
        this.cameraDone = false;
        if (!this.fotoTaken) this.$refs.cam.toggleMask();
        this.fotoTaken = null;
        this.$refs.cam.start();
      }
    },
    cancelBtn() {
      this.$refs.cam.stop();
      this.$router.push({
        name: "EmpleadoDetalle",
        params: { id: this.empleado_id },
      });
    },
    onSubmit() {
      //this.$refs.cam.stop();
      this.loading = true;

      let fotoObj = {
        persona: this.empleado_id,
        foto: this.fotoTaken,
      };

      if (this.empleadoFoto == "actualizar") {
        //console.log("actualizando! " + this.empleadoFoto);
        EmpleadosService.putPersonaFoto(fotoObj)
          .then((response) => {
            this.generarEncodings();
          })
          .catch((error) => {
            this.error = "Error: No se pudo actualizar la foto.";
            this.resetState();
          });
      } else {
        //console.log("creando!" + this.empleadoFoto);
        EmpleadosService.postPersonaFoto(fotoObj)
          .then((response) => {
            this.empleadoFoto = "actualizar";
            this.generarEncodings();
          })
          .catch((error) => {
            this.error = "Error: No se pudo guardar la foto.";
            this.resetState();
          });
      }
    },
    generarEncodings() {
      EmpleadosService.postEncoding(this.empleado_id)
        .then((response) => {
          this.$refs.cam.stop();
          this.$router.push({
            name: "EmpleadoDetalle",
            params: { id: this.empleado_id },
          });
        })
        .catch((error) => {
          this.error =
            "Ocurrió un error al generar la codificación del rostro.";
          this.resetState();
        });
    },

    resetState() {
      this.loading = false;
      //this.fotoTaken = null;
      this.camera = false;
      this.cameraOnOff();
    },

    validate() {
      this.$refs.form.validate();
    },
  },
};
</script>
<style scoped>
.text-upper input {
  text-transform: uppercase;
}

.camera-viewport {
  position: absolute;

  /* left: -100px; */
  top: 0px;
  height: 140%;
  width: 140%;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
</style>
<style>
.overlay {
  position: absolute;
  left: 100%;
  transform: translateX(-96%) !important;
  height: 100%;
  width: auto;
  display: flex;
}
</style>
