<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Nuevo Empleado</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <EmpleadoForm></EmpleadoForm>
    </div>
  </v-container>
</template>
<script>
import EmpleadoForm from "../components/EmpleadoForm.vue";

export default {
  name: "EmpleadoAlta",
  components: {
    EmpleadoForm,
  },
  data() {
    return {
      ingresos: null,
      datosDni: {},
    };
  },
  created() {},
};
</script>
