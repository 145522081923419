<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Enrolar Empleado</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <EmpleadoEnrolarForm
        :empleado_id="id"
        :empleadoFoto="nuevo"
      ></EmpleadoEnrolarForm>
    </div>
  </v-container>
</template>

<script>
import EmpleadoEnrolarForm from "../components/EmpleadoEnrolarForm.vue";

export default {
  name: "EmpleadoEnrolar",
  components: {
    EmpleadoEnrolarForm,
  },
  data() {
    return {};
  },
  props: ["id", "nuevo"],
  created() {},
};
</script>
