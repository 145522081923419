<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Nuevo Lugar</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <LugarForm></LugarForm>
    </div>
  </v-container>
</template>

<script>
import LugarForm from "../components/LugarForm.vue";

export default {
  name: "LugarAlta",
  components: {
    LugarForm,
  },
  data() {
    return {};
  },
  //created() {},
};
</script>
